import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, tap } from 'rxjs'
import { HocIntl } from '@dn/hocs'
import { StoreState } from '../../../models/app/model'
import { getMessages } from '../../../intl/get-messages'
import { IntlAC, IntlAT } from '../../actions/intl/actions'
import { Config } from '../../../config'

type Action = ReturnType<typeof IntlAC.changeLang>

type GetMessagesObs = {
  lang: Lang
  messages: Messages
}

export const changeLangEpic$ = (action$: Observable<Action>, state$: StateObservable<StoreState>) =>
  action$.pipe(
    ofType(IntlAT.CHANGE_LANG),

    mergeMap(({ payload }) => {
      const obs$ = new Observable<GetMessagesObs>((observer) => {
        getMessages(payload, true)
          .then(({ messages }) => {
            observer.next({
              lang: payload,
              messages: messages as any,
            })
          })
          .catch((err) => {
            observer.complete()
          })
      })

      return obs$
    }),

    tap(({ lang }) => {
      document.documentElement.lang = lang
    }),

    map(({ lang, messages }) =>
      HocIntl.Mutators.MC.chLang(Config.Intl.SupportedLocales, lang, messages),
    ),
  )
