import React, { HTMLInputAutoCompleteAttribute, useEffect, useId, useRef, useState } from 'react'

import { useTrans } from '../../../hooks/use-trans'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { InfosList } from '../infos/list/component'
import { Trans } from '../intl/trans'
import { STIntlInput, STProps } from './style'

// ~~~~~~ Constants

const EyeViewIcon = LazySVG('icons/eye-view')
const EyeNoViewIcon = LazySVG('icons/eye-no-view')

const InfoIcon = LazySVG('icons/info')

// ~~~~~~ Types

type Icon = ReturnType<typeof LazySVG>

type Props = Omit<STProps, '$hasErrors' | '$isPassword'> & {
  tabIndex?: number
  autoComplete?: HTMLInputAutoCompleteAttribute

  className?: string

  type?: string
  value: string | number

  label?: IntlMsgId
  sublabel?: IntlMsgId

  ariaLabel?: IntlMsgId

  placeholder?: IntlMsgId
  placeholderValues?: { [key: string]: any }

  errors?: Infos[]

  showErrorsInBox?: true
  pattern?: string
  Icon?: Icon
  doFocus?: number

  onChange: (value: string) => void
  onFocus?: (ref: HTMLInputElement) => void
  onBlur?: (value: string) => void
  onKeyDown?: (key: string) => void
}

export const IntlInput: React.FC<Props> = ({
  tabIndex,
  autoComplete = 'off',

  className,

  type = 'text',
  value,

  label,
  sublabel,

  ariaLabel,

  placeholder,
  placeholderValues,

  errors,

  pattern,
  showErrorsInBox,
  Icon,
  doFocus,

  // Shared

  $disabled,

  // Style

  $hideBorder,
  $height,
  $iconWidth,
  $textAlign,

  onChange,
  onFocus,
  onBlur,
  onKeyDown,
}) => {
  // ~~~~~~ Hooks

  const trans = useTrans(placeholder || 'wildcard', placeholder ? placeholderValues : { value: '' })

  const placeholderText = placeholder ? trans : ''

  const finalAriaLabel = useTrans(ariaLabel || 'wildcard')

  // ~~~~~~ State

  const [storedDoFocus, setStoredDoFocus] = useState(0)

  const [showPass, setShowPass] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const id = useId()

  // ~~~~~~ Computed

  const finalType = type === 'password' ? (showPass ? 'text' : 'password') : type

  // ~~~~~~ Handlers

  function onClickTogglePass() {
    setShowPass(!showPass)
  }

  function onChangeH(evt: any) {
    onChange(evt.target.value)
  }

  function onFocusH() {
    onFocus && inputRef.current && onFocus(inputRef.current)
  }

  function onBlurH() {
    onBlur && onBlur(`${value}`)
  }

  function onClickH() {
    onFocus && inputRef.current && onFocus(inputRef.current)
  }

  function onKeyDownH(evt: any) {
    onKeyDown && onKeyDown(evt.key)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!doFocus || !inputRef.current || doFocus === storedDoFocus) return

    setStoredDoFocus(doFocus)

    inputRef.current.focus()
  }, [doFocus, storedDoFocus])

  // ~~~~~~ Render

  return (
    <STIntlInput
      tabIndex={tabIndex}
      className={className}
      $hideBorder={$hideBorder}
      $hasErrors={!!errors?.length}
      $height={$height}
      $hasIcon={!!Icon}
      $disabled={$disabled}
      $iconWidth={$iconWidth}
      $isPassword={type === 'password'}
      $textAlign={$textAlign}
    >
      {label ? (
        <div className="label">
          <label htmlFor={id}>
            <Trans id={label} />
          </label>
        </div>
      ) : undefined}

      {sublabel ? (
        <div className="sublabel">
          <Trans id={sublabel} />
        </div>
      ) : undefined}

      {Icon ? (
        <div className="intl-icon-container">
          <div className="intl-icon">
            <Icon size={16} />
          </div>
        </div>
      ) : undefined}

      {type === 'password' ? (
        <div className="show-pass" onClick={onClickTogglePass}>
          {showPass ? <EyeNoViewIcon size={16} /> : <EyeViewIcon size={16} />}
        </div>
      ) : undefined}

      <input
        id={id}
        aria-label={ariaLabel ? finalAriaLabel : undefined}
        tabIndex={tabIndex}
        ref={inputRef}
        type={finalType}
        autoComplete={autoComplete}
        placeholder={placeholderText}
        disabled={$disabled}
        value={value}
        pattern={pattern}
        onChange={onChangeH}
        onFocus={onFocusH}
        onBlur={onBlurH}
        onClick={onClickH}
        onKeyDown={onKeyDownH}
      />

      {errors?.length ? (
        showErrorsInBox ? (
          <div className="intl-input-error">
            <div className="intl-input-error-icon">
              <InfoIcon size={16} />
            </div>

            <div className="intl-input-error-list">
              <InfosList infos={errors} />
            </div>
          </div>
        ) : (
          <InfosList infos={errors} />
        )
      ) : undefined}
    </STIntlInput>
  )
}
