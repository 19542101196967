import 'react-app-polyfill/stable'

import { ReactElement } from 'react'
import { compose } from 'redux'
import { createRoot } from 'react-dom/client'
import { fromEvent, take } from 'rxjs'

import { ServiceBcast } from '@dn/bcast'
import { ConstEventsElement, ConstEventsWindow } from '@dn/constants'
import { HocAuth0, HocIntl, HocRouter, HocStore, HocTheme } from '@dn/hocs'

import { App } from './components/app'

import { getMessages, GetMessagesErr } from './intl/get-messages'
import { ExtensionService } from './services/extension'
import { Config } from './config'
import { AppInitState } from './models/app/model'
import { GlobalStyle } from './style/global-style'
import { getStore } from './store/store-config'
import { BcastTrackEvents } from './services/track-events'

// NOTE: As this file is copied in src folder as index.tsx
// paths should be relatives to this file
import './style/fonts/fonts.css'

// Third parties
import { DNSentry } from './third-parties/sentry/library'

// @ts-ignore
BigInt.prototype['toJSON'] = function () {
  return this.toString()
}

// ~~~~~~ Helpers

const renderApp = (ProvidedApp: () => ReactElement) => {
  const container = document.getElementById('root') as HTMLDivElement

  const root = createRoot(container)

  root.render(<ProvidedApp />)
}

// ~~~~~~ Init Sentry

DNSentry.init()

// ~~~~~~ Init extension service

ExtensionService.init()

// ~~~~~~~ Provide App Promise

const AppPromise = getMessages()
  .then(({ locale, messages }) => {
    document.documentElement.lang = locale

    const ProvidedApp = compose<typeof App>(
      HocStore.genProvider(getStore()),
      HocTheme.genProvider(AppInitState, GlobalStyle),
      HocIntl.genProvider(Config.Intl.SupportedLocales, AppInitState, locale, messages),
      HocRouter.withProvider,
      HocAuth0.genProvider(Config.ThirdParty.Auth0),
    )(App)

    return ProvidedApp
  })
  .catch((err: GetMessagesErr) => {
    return Promise.reject()
  })

// ~~~~~~ Prevent pinch-to-zoom in IOs

fromEvent(document.documentElement, ConstEventsElement.SafariGestureStart).subscribe({
  next: (evt) => evt.preventDefault(),
})

// ~~~~~~ Init React App

fromEvent(window, ConstEventsWindow.Load)
  .pipe(take(1))
  .subscribe({
    next: () => {
      // ~~~~~~ Init track events

      BcastTrackEvents.init(Config.Features.DebugAnalytics ? { withLogs: true } : undefined)

      Config.Logs.Bcast2Service && ServiceBcast.setLog()

      // ~~~~~~ Init react app

      AppPromise.then((ProvidedApp) => renderApp(ProvidedApp)).catch(() => undefined)
    },
  })
