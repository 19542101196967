import { useEffect, useRef, KeyboardEvent, ClipboardEvent } from 'react'
import { ConstKeyCodes } from '@dn/constants'
import { UtilsNumber } from '@dn/utils'
import { STBroadcastIdInput } from './style'
import { useTrans } from '../../../../hooks/use-trans'

// ~~~~~~ Constants

const IDLength = 6

// ~~~~~~ Types

type Position = 0 | 1 | 2 | 3 | 4 | 5

type Props = {
  id: string
  disabled: boolean
  onChangeId: (value: string) => void
}

// ~~~~~~ Component

export const BroadcastIdInput: React.FC<Props> = ({ id, onChangeId, disabled }) => {
  // ~~~~~~ Hooks

  const code01txt = useTrans('common.Code01')

  const code02txt = useTrans('common.Code02')

  const code03txt = useTrans('common.Code03')

  const code04txt = useTrans('common.Code04')

  const code05txt = useTrans('common.Code05')

  const code06txt = useTrans('common.Code06')

  // ~~~~~~ State

  // - Refs

  const isOnPasteFiredRef = useRef(false)

  const button0Ref = useRef<HTMLInputElement>(null)

  const button1Ref = useRef<HTMLInputElement>(null)

  const button2Ref = useRef<HTMLInputElement>(null)

  const button3Ref = useRef<HTMLInputElement>(null)

  const button4Ref = useRef<HTMLInputElement>(null)

  const button5Ref = useRef<HTMLInputElement>(null)

  // ~~~~~~ Helpers

  function focusNextInput(position: number) {
    switch (position) {
      case 0:
        button1Ref.current?.focus()
        break

      case 1:
        button2Ref.current?.focus()
        break

      case 2:
        button3Ref.current?.focus()
        break

      case 3:
        button4Ref.current?.focus()
        break

      case 4:
        button5Ref.current?.focus()
        break
    }
  }

  function focusPrevInput(position: number) {
    switch (position) {
      case 1:
        button0Ref.current?.focus()
        break

      case 2:
        button1Ref.current?.focus()
        break

      case 3:
        button2Ref.current?.focus()
        break

      case 4:
        button3Ref.current?.focus()
        break

      case 5:
        button4Ref.current?.focus()
        break
    }
  }

  function focusInput(position: number) {
    switch (position) {
      case 0:
        button0Ref.current?.focus()
        break

      case 1:
        button1Ref.current?.focus()
        break

      case 2:
        button2Ref.current?.focus()
        break

      case 3:
        button3Ref.current?.focus()
        break

      case 4:
        button4Ref.current?.focus()
        break

      case 5:
        button5Ref.current?.focus()
        break
    }
  }

  // ~~~~~~ Handlers

  function onChangeIdH(position: Position, value: string) {
    //
    // An onChange is always fired after an onPaste
    // we avoid this behavior cancelling the onChange following an onPaste

    if (isOnPasteFiredRef.current) {
      isOnPasteFiredRef.current = false
      return
    }

    const validValue = value.slice(0, 1)

    const numberStr = UtilsNumber.safeStrOfNumberInt(validValue)

    const curValue = id.slice().split('')

    curValue[position] = numberStr || '-'

    onChangeId(curValue.join(''))

    if (curValue[position] === '-') return

    focusNextInput(position)
  }

  // NOTE: Safari and Chrome fire onPaste and onChange in a async way
  //       but firefox fire this events sync, so component doesn't have
  //       a chance to be re-render

  function onPaste(evt: ClipboardEvent<HTMLInputElement>, position: number, text: string) {
    const value = text.slice(position, IDLength)

    if (value !== UtilsNumber.safeStrOfNumberInt(value)) {
      evt.preventDefault()

      return
    }

    const orignalValues = id.slice(0, position)

    const values = value

    const finalValues = `${orignalValues}${values}`.padEnd(IDLength, '-')

    const focusPos = finalValues.search('-')

    onChangeId(finalValues)

    focusPos === -1 ? focusInput(IDLength - 1) : focusInput(focusPos)

    // Tell onChange that is following an onPaste in order to
    // cancel its execution

    isOnPasteFiredRef.current = true
  }

  function onKeyDownInput(
    evt: KeyboardEvent<HTMLInputElement>,
    position: Position,
    keyCode: string,
  ) {
    // Windows and Ubuntu needs ctrlKey and Mac metaKey

    if (evt.metaKey || evt.ctrlKey) return

    if (/[0-9]/.test(keyCode)) return

    if (keyCode === ConstKeyCodes.BackSpace && position > 0) {
      if (id[position] === '-') {
        focusPrevInput(position)
      }

      return
    }

    evt.preventDefault()
  }

  // ~~~~~~ Effects

  // - On mount: Focus first input

  useEffect(() => {
    button0Ref.current?.focus()

    //
  }, [])

  // ~~~~~~ Render

  return (
    <STBroadcastIdInput>
      {/* 0 */}

      <input
        data-testid="code01-input"
        aria-label={code01txt}
        ref={button0Ref}
        type="number"
        value={id.slice(0, 1) === '-' ? '' : id.slice(0, 1)}
        disabled={disabled}
        onChange={(evt) => onChangeIdH(0, evt.target.value)}
        onFocus={() => button0Ref.current?.select()}
        onPaste={(evt) => onPaste(evt, 0, evt.clipboardData.getData('text'))}
        onKeyDown={(evt) => onKeyDownInput(evt, 0, evt.key)}
      />

      {/* 1 */}

      <input
        data-testid="code02-input"
        aria-label={code02txt}
        ref={button1Ref}
        type="number"
        value={id.slice(1, 2) === '-' ? '' : id.slice(1, 2)}
        disabled={disabled}
        onChange={(evt) => onChangeIdH(1, evt.target.value)}
        onFocus={() => button1Ref.current?.select()}
        onPaste={(evt) => onPaste(evt, 1, evt.clipboardData.getData('text'))}
        onKeyDown={(evt) => onKeyDownInput(evt, 1, evt.key)}
      />

      {/* 2 */}

      <input
        data-testid="code03-input"
        aria-label={code03txt}
        ref={button2Ref}
        type="number"
        value={id.slice(2, 3) === '-' ? '' : id.slice(2, 3)}
        disabled={disabled}
        onChange={(evt) => onChangeIdH(2, evt.target.value)}
        onFocus={() => button2Ref.current?.select()}
        onPaste={(evt) => onPaste(evt, 2, evt.clipboardData.getData('text'))}
        onKeyDown={(evt) => onKeyDownInput(evt, 2, evt.key)}
      />

      {/* 3 */}

      <input
        data-testid="code04-input"
        aria-label={code04txt}
        ref={button3Ref}
        type="number"
        value={id.slice(3, 4) === '-' ? '' : id.slice(3, 4)}
        disabled={disabled}
        onChange={(evt) => onChangeIdH(3, evt.target.value)}
        onFocus={() => button3Ref.current?.select()}
        onPaste={(evt) => onPaste(evt, 3, evt.clipboardData.getData('text'))}
        onKeyDown={(evt) => onKeyDownInput(evt, 3, evt.key)}
      />

      {/* 4 */}

      <input
        data-testid="code05-input"
        ref={button4Ref}
        aria-label={code05txt}
        type="number"
        value={id.slice(4, 5) === '-' ? '' : id.slice(4, 5)}
        disabled={disabled}
        onChange={(evt) => onChangeIdH(4, evt.target.value)}
        onFocus={() => button4Ref.current?.select()}
        onPaste={(evt) => onPaste(evt, 4, evt.clipboardData.getData('text'))}
        onKeyDown={(evt) => onKeyDownInput(evt, 4, evt.key)}
      />

      {/* 5 */}

      <input
        data-testid="code06-input"
        ref={button5Ref}
        aria-label={code06txt}
        type="number"
        value={id.slice(5, 6) === '-' ? '' : id.slice(5, 6)}
        disabled={disabled}
        onChange={(evt) => onChangeIdH(5, evt.target.value)}
        onFocus={() => button5Ref.current?.select()}
        onPaste={(evt) => onPaste(evt, 5, evt.clipboardData.getData('text'))}
        onKeyDown={(evt) => onKeyDownInput(evt, 5, evt.key)}
      />

      {/* - */}
    </STBroadcastIdInput>
  )
}
